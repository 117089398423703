import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { AlertifyService } from '../_services/alertify.service';



@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
model: any = {};

  constructor( private authservice: AuthService, private alertify: AlertifyService) { }

  ngOnInit() {
  }
  login() {
    this.authservice.login(this.model).subscribe(data => {
      this.alertify.succes('Connecter avec succes');
    } , error => {
      this.alertify.error(error);
    });

  }
  connecte () {
   /* const token = localStorage.getItem('token');
    return !!token;*/
    return !this.authservice.connecte();
  }

  logout() {
    this.authservice.usertoken = null;
    localStorage.removeItem('token');
   this.alertify.message('Utilisateur déconnecté');
  }

}
