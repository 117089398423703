import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { AlertifyService } from '../_services/alertify.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
model: any = {};
@Output() AnnulerEnregitrement = new EventEmitter();
  constructor(private authservice: AuthService,  private alertify: AlertifyService) { }

  ngOnInit() {
  }

  enregistrer() {
    this.authservice.register(this.model).subscribe(() => {
      this.alertify.succes('Enregistrer avec succes');
    } , error => {
      this.alertify.error(error);
    });
  }

  annuler() {
    this.AnnulerEnregitrement.emit(false);
  }
}
