import { Injectable } from '@angular/core';
declare let alertify: any;

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {

constructor() { }


confirm(message: string, okretour: () => any) {
  alertify.confim(message, function(e) {
    if (e) {
      okretour();
    } else {
    }
  });
}

succes(message: string) {
  alertify.success(message);
}

error(message: string) {
  alertify.error(message);
}

warning(message: string) {
  alertify.warning(message);
}

message(message: string) {
  alertify.message(message);
}



}
