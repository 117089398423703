import { Component, OnInit } from '@angular/core';
import { Http } from '@angular/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  ModeEnregistrement = false;
  values: any;

  constructor(private http: Http) { }

  ngOnInit() {
  }
  ActionEnregistrer() {
    this.ModeEnregistrement = true;
      }
  AnnulerModeEnregitrement(ModeEnregistrement: boolean) {
        this.ModeEnregistrement = ModeEnregistrement;
      }
}
