import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, throwError} from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl = 'http://localhost:5000/api/auth/';
  usertoken: any = null;
  jwtHelper: JwtHelperService = new JwtHelperService();
  decodetoken: any;

constructor(private http: Http) { }

login(model: any) {
  return this.http.post(this.baseUrl + 'login', model, this.requestOptions()).pipe (
  map((response: Response) => {
    const user = response.json();
    if (user && user.tokenstring) {
      localStorage.setItem('token', user.tokenstring);
      this.decodetoken = this.jwtHelper.decodeToken(user.tokenstring);
      console.log(this.decodetoken);
      this.usertoken  = user.tokenstring;
    }
  })
  , catchError(this.handleError));
}

connecte () {
  return this.jwtHelper.isTokenExpired(this.usertoken);
}

register(model: any) {
  return this.http.post(this.baseUrl + 'register', model, this.requestOptions()).pipe( catchError(this.handleError));
}

private requestOptions() {
  const header = new Headers({'content-type': 'application/json'});
  return new RequestOptions({headers: header});
}

private handleError(error: any) {
  const applicationError = error.headers.get('Application-Error');
if (applicationError) {
  return throwError (applicationError);
  }
const serverError = error.json();
let modelStateErrors = '';
if (serverError) {
  for (const key in serverError) {
    if (serverError[key]) {
      modelStateErrors += serverError[key] + '\n';
    }
  }
}
return throwError( modelStateErrors || 'Server error');

}

}
